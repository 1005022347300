var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.data
    ? _c(
        "div",
        [
          _c("span", {
            domProps: {
              innerHTML: _vm._s(_vm.data.substring(0, _vm.subString))
            }
          }),
          _vm.isEllipis ? _c("span", [_vm._v("...")]) : _vm._e(),
          _c("Tooltip", {
            attrs: {
              hoverText: _vm.data,
              subString: _vm.subString,
              position: _vm.position
            }
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }