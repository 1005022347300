<template>
  <div v-if="data">
    <span v-html="data.substring(0, subString)"></span><span v-if="isEllipis">...</span>
    <Tooltip :hoverText="data" :subString="subString" :position="position" />
  </div>
</template>

<script>
export default {
  components: {
    Tooltip: () => import(/* webpackPrefetch: true */ "./Tooltip"),
  },
  props: {
    data: {
      type: String,
    },
    subString: {
      type: Number,
      default: 12,
    },
    position: {
      type: String,
      default: "top",
    },
  },
  computed: {
    isEllipis() {
      return this.data.length > this.subString ? true : false;
    },
  },
};
</script>
